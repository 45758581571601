import { Asset, RichText, RichTextProps } from '@graphcommerce/graphcms-ui'
import { ColumnOne } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import Script from 'next/script'
import type { RowColumnOneFragment } from './RowColumnOne.gql'
import { removePublitasIframes } from './removePublitasIframes'

export type RowColumnOneProps = RowColumnOneFragment & {
  richTextOne?: Omit<RichTextProps, 'raw'>
}

export function RowColumnOne(props: RowColumnOneProps) {
  const { colOne, richTextOne, publitasEmbed } = props
  //  This feature may be removed after this issue goes live and the old publitas iframes are remove from the content, see https://hoproj.atlassian.net/browse/DAGC-841 for references
  const cleanedContent = removePublitasIframes(colOne)

  return (
    <ColumnOne maxWidth='lg'>
      <RichText
        {...cleanedContent}
        {...richTextOne}
        sxRenderer={{
          iframe: {
            aspectRatio: { xs: `9 / 16`, md: `16 / 11` },
          },
        }}
        renderers={{
          image: ({ src, width, height, title, mimeType }) => (
            <Box sx={{ width, maxWidth: '100%' }}>
              <Asset asset={{ url: src, alt: title, width, height, mimeType }} />
            </Box>
          ),
        }}
      />
      {publitasEmbed?.url && (
        <Box mb={4}>
          <div id={publitasEmbed.id} />
          {/* eslint-disable-next-line @next/next/no-sync-scripts */}
          <Script
            data-cfasync='false'
            data-height='undefined'
            data-publication={publitasEmbed.url}
            data-publication-aspect-ratio='undefined'
            data-responsive='true'
            data-width='undefined'
            data-wrapper-id={publitasEmbed.id}
            // eslint-disable-next-line react/no-unknown-property
            publitas-embed='true'
            src='https://view.publitas.com/embed.js'
            type='text/javascript'
          />
        </Box>
      )}
    </ColumnOne>
  )
}
