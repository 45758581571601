export function removePublitasIframes(obj) {
  // If the input isn't an object or is null, return it unchanged
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // If the object is an array, filter out any items that are iframes with publitas URLs
  // and recursively process the remaining items
  if (Array.isArray(obj)) {
    return obj
      .filter((item) => {
        // Filter out any item that is an iframe with a publitas URL
        if (item.type === 'iframe' && item.url && item.url.includes('publitas')) {
          return false
        }
        return true
      })
      .map((item) => removePublitasIframes(item))
  }

  // For regular objects, process each property recursively
  const result = {}
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.entries(obj).forEach(([key, value]) => {
    result[key] = removePublitasIframes(value)
  })

  return result
}
